import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import Login from "./login.js";
import Logo from "../images/nav-logo.png";

import "./css/header.css";

class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isVisible: false,
		};
		this.toggleBox = this.toggleBox.bind(this);
		this.toggleMenu = this.toggleMenu.bind(this);
	}

	toggleBox() {
		this.setState(
			this.state.isVisible
				? this.setState({ isVisible: false })
				: this.setState({ isVisible: true })
		);
	}

	toggleMenu() {
		var menu = document.querySelector(".header-menu");

		menu.classList.contains("active") ? this.closeMenu() : this.openMenu();
	}

	openMenu() {
		var menu = document.querySelector(".header-menu");
		var links = menu.querySelectorAll("ul.menu-items li");

		menu.classList.add("active");

		var delay = 150;
		links.forEach((link) => {
			setTimeout(() => {
				if (!link.classList.contains("header-login-btn"))
					link.addEventListener("click", this.closeMenu);

				link.classList.remove("transition");
			}, delay);

			delay += 50;
		});
	}

	closeMenu() {
		var delay = 0;
		var menu = document.querySelector(".header-menu");
		var links = menu.querySelectorAll("ul.menu-items li");
		menu.classList.add("fast");

		links.forEach((link, i) => {
			setTimeout(() => {
				if (!link.classList.contains("header-login-btn"))
					link.removeEventListener("click", this.closeMenu);

				link.classList.add("transition");
			}, delay);

			delay += 25;

			if (i === links.length - 1) {
				setTimeout(() => {
					menu.classList.remove("active");
				}, delay + 25);
				setTimeout(() => {
					menu.classList.remove("fast");
				}, delay + 50);
			}
		});
	}

	render() {
		return (
			<>
				<div className="header">
					<a href="/">
						<img alt="kodarkivet" className="logotype" src={Logo} />
					</a>
					<div className="header-menu">
						<button
							className="burger"
							type="button"
							aria-label="toggle menu"
							onClick={this.toggleMenu}
						>
							<span aria-hidden="true"></span>
						</button>
						<ul className="menu-items">
							<li className="transition">
								<NavLink exact activeClassName="active" to="/">
									Hem
								</NavLink>
							</li>
							<li className="transition">
								<NavLink
									exact
									activeClassName="active"
									to="/about"
								>
									Företaget
								</NavLink>
							</li>
							<li className="transition">
								<NavLink
									exact
									activeClassName="active"
									to="/portfolio"
								>
									Portfölj
								</NavLink>
							</li>

							{this.props.isLoggedIn ? (
								<Fragment>
									<li className="transition">
										<NavLink
											exact
											activeClassName="active"
											to="/user"
										>
											Mina Sidor
										</NavLink>
									</li>
									<li
										className="header-login-btn noselect transition"
										onClick={this.props.handleLogout}
									>
										Logga ut
									</li>
								</Fragment>
							) : 
								<li
									className="header-login-btn noselect transition"
									onClick={this.toggleBox}
								>
									Logga in
								</li>
								
							}

							<ul className="social-icons">
								<a target="_blank" href="https://www.facebook.com/profile.php?id=100092325399489">
									<li className="fab fa-facebook-f"></li>
								</a>
								<a target="_blank" href="https://www.instagram.com">
									<li className="fab fa-instagram"></li>
								</a>
								<a target="_blank" href="https://www.linkedin.com">
									<li className="fab fa-linkedin"></li>
								</a>
							</ul>
						</ul>
					</div>
				</div>
				<Login
					handleLogin={this.props.handleLogin}
					burgerState={this.props.burgerState}
					user={this.props.user}
					isVisible={this.state.isVisible}
					toggleBox={this.toggleBox}
				/>
			</>
		);
	}
}

export default Header;
