import React from "react";
import "./css/login.css";

import axios from "axios";
import {config} from "../config.js";

class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			Email: "",
			Password: "",
			Error: false,
			ErrorMessage: "",
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleSubmit(e) {
		e.preventDefault();
		axios
			.post(config.protocol + "://" + config.domain.api + "/login", {
				email: this.state.Email,
				password: this.state.Password,
			})
			.then((response) => {
				if (!response.data)
					this.setState({
						Error: true,
						ErrorMessage: "Fel e-post eller lösenord.",
					});
				else {
					const access_token = response.data.token;
					const user = response.data.user;
					localStorage.setItem("sid", access_token);
					this.props.handleLogin(user);

					if (this.props.toggleBox) this.props.toggleBox();

					window.location.href = "/user";
				}
			})
			.catch((exception) => {
				if (!exception.status)
					this.setState({
						Error: true,
						ErrorMessage: "Nätverksfel, försök igen senare.",
					});
			});
	}

	handleUnFocus(e) {
		const Input = e.target;
		Input.value
			? Input.classList.add("Has-Value")
			: Input.classList.remove("Has-Value");
	}

	handleInputChange(e) {
		const name = e.target.name;
		const value = e.target.value;
		this.setState({ [name]: value });
	}

	render() {
		return (
			<>
				<div
					id="Login-Form"
					className={this.props.isVisible ? "Login-Form-Show" : null}
				>
					<form onSubmit={this.handleSubmit}>
						{this.props.burgerState ? (
							<i
								onClick={this.props.toggleBox}
								className="Exit-Login fas fa-times"
							></i>
						) : null}
						<h3>Logga in</h3>
						{this.state.Error ? (
							<p style={{ color: "red", fontSize: ".7rem" }}>
								{this.state.ErrorMessage}
							</p>
						) : null}
						<div className="Wrap-Input">
							<input
								type="text"
								name="Email"
								onChange={this.handleInputChange}
								onBlur={this.handleUnFocus}
								className="Login-Input Input"
								required
							></input>
							<span className="Login-Input Focus-Input">E-post</span>
						</div>
						<div className="Wrap-Input">
							<input
								type="password"
								name="Password"
								onChange={this.handleInputChange}
								onBlur={this.handleUnFocus}
								className="Login-Input Input"
								autoComplete="on"
								required
							></input>
							<span className="Login-Input Focus-Input">Lösenord</span>
						</div>
						<button type="submit">Logga in</button>
					</form>
					<p className="Login-Info">Har ni en prenumeration hos oss? Då ska ni ha fått ett mail med inloggningsuppgifter.</p>
				</div>
			</>
		);
	}
}

export default Login;
