import React from "react";
import "./css/user.css";

class User extends React.Component {
	render() {
		return (
			<>
				<div className="Path-Container">
					<div className="Path-User">
						<div className="P-W">
							<h1>Mina sidor</h1>
							<div></div>
							<div className="User-Box">
								<div className="User-Info">
									<div>
										<label>Användarnamn</label>
										<span>{this.props.user.username}</span>
									</div>
									<div>
										<label>E-post</label>
										<span>{this.props.user.email}</span>
									</div>
									<div>
										<label>Namn</label>
										<span>
											{this.props.user.firstname}{" "}
											{this.props.user.lastname}
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default User;
