import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "./app.css";

import { PrivateRoute, AdminRoute } from "./helpers/private-route.js";

import Header from "./components/header.js";
import Sidebar from "./components/sidebar.js";

import Login from "./paths/login.js";
import Home from "./paths/home.js";
import About from "./paths/about.js";
import User from "./paths/user.js";
import Portfolio from "./paths/portfolio.js";
import FourZeroFour from "./paths/404.js";
import Unsubscribe from "./paths/Unsubscribe.js";

import { VerifyUser } from "./helpers/verify-user.js";
import MessageBox from "./components/message-box.js";

class App extends React.Component {
	constructor() {
		super();
		this.state = {
			isLoggedIn: false,
			isLoading: true,
			isAdmin: false,
			user: {},
			MessageText: "",
			burgerState: false,
			version: 1,
		};

		this.handleLogin = this.handleLogin.bind(this);
		this.handleLogout = this.handleLogout.bind(this);
		this.handleMessage = this.handleMessage.bind(this);
		this.checkViewPort = this.checkViewPort.bind(this);
	}

	handleLogin(user) {
		this.setState({
			isLoggedIn: true,
			user: user,
		});
	}

	handleMessage(message) {
		const MessageBox = document.querySelector(".MessageBox");
		if (message === "") {
			setTimeout(function () {
				MessageBox.classList.remove("show");
			}, 100);
		}

		setTimeout(
			function () {
				this.setState({
					MessageText: message,
				});
			}.bind(this),
			700
		);
	}

	handleLogout() {
		this.setState({
			isLoggedIn: false,
			isLoading: true,
			user: {},
		});

		localStorage.removeItem("sid");
		document.location.href = "/";
	}

	checkScrollTop() {
		var header = document.querySelector(".header");
		var scrollTop =
			window.pageYOffset ||
			(
				document.documentElement ||
				document.body.parentNode ||
				document.body
			).scrollTop;

		if (scrollTop >= 20) header.classList.add("scrolled");
		else header.classList.remove("scrolled");
	}

	async checkLoginStatus() {
		const token = localStorage.getItem("sid");
		if (token) {
			const user = await VerifyUser(token);
			if (user) {
				this.setState({
					isLoggedIn: true,
					isLoading: false,
					user: user,
				});
			} else {
				this.setState({
					isLoggedIn: false,
					isLoading: false,
					user: {},
				});
			}
		} else {
			this.setState({
				isLoggedIn: false,
				isLoading: false,
				user: {},
			});
		}
	}

	checkViewPort() {
		var FrameWith = window.innerWidth;
		if (FrameWith <= 980) {
			this.setState({
				burgerState: true,
			});
		} else {
			this.setState({
				burgerState: false,
			});
		}
	}

	componentDidMount() {
		this.checkViewPort();
		this.checkLoginStatus();
		window.addEventListener("resize", this.checkViewPort);
		window.addEventListener("scroll", this.checkScrollTop);
	}

	render() {
		return (
			<>
				<Router onUpdate={() => window.scrollTo(0, 0)}>
					<Header
						handleLogin={this.handleLogin}
						burgerState={this.state.burgerState}
						isLoggedIn={this.state.isLoggedIn}
						handleLogout={this.handleLogout}
						key={this.state.version}
					/>
					{this.state.MessageText === "" ? null : (
						<MessageBox
							handleMessage={this.handleMessage}
							MessageText={this.state.MessageText}
						/>
					)}

					<Switch>
						<Route
							exact
							path="/"
							render={(props) => (
								<Home
									{...props}
									burgerState={this.state.burgerState}
									handleMessage={this.handleMessage}
									isLoggedIn={this.state.isLoggedIn}
									key={this.state.version}
								/>
							)}
						/>
						<Route
							exact
							path="/about"
							render={(props) => (
								<About
									{...props}
									handleMessage={this.handleMessage}
									key={this.state.version}
								/>
							)}
						/>
						<Route
							exact
							path="/portfolio"
							render={(props) => (
								<Portfolio
									{...props}
									key={this.state.version}
								/>
							)}
						/>
						<Route
							exact
							path="/unsubscribe"
							render={(props) => (
								<Unsubscribe
									{...props}
									key={this.state.version}
								/>
							)}
						/>
						<Route
							exact
							path="/404"
							render={(props) => (
								<FourZeroFour
									{...props}
									key={this.state.version}
								/>
							)}
						/>
						<Route
							exact
							path="/login"
							render={(props) => (
								<Login
									{...props}
									handleLogin={this.handleLogin}
									key={this.state.version}
								/>
							)}
						/>
						<PrivateRoute
							exact
							path="/user"
							component={User}
							isLoggedIn={this.state.isLoggedIn}
							isLoading={this.state.isLoading}
							user={this.state.user}
							key={this.state.version}
						/>
						<AdminRoute
							exact
							path="/admin"
							component={User}
							isAdmin={this.state.isAdmin}
							isLoading={this.state.isLoading}
							user={this.state.user}
							key={this.state.version}
						/>
					</Switch>
				</Router>
				<Sidebar />
			</>
		);
	}
}

export default App;
