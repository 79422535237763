export const config = {
	protocol: "https",
	domain: {
		main: "kodarkivet.se",
		api: "api.kodarkivet.se",
		admin: "admin.kodarkivet.se"
	},
};

export const _config = {
	protocol: "http",
	domain: {
		main: "localhost:5000",
		api: "localhost:3000",
		admin: "admin.kodarkivet.se"
	},
};
