import React, { Component } from "react";
import "./css/sidebar.css";

class Sidebar extends Component {
	render() {
		return (
			<>
				<div className="sidebar">
					<ul className="social-logos">
						<div>
							<a target="_blank" href="https://www.facebook.com/profile.php?id=100092325399489">
								<li className="fab fa-facebook-f"></li>
							</a>
						</div>
						<div>
							<a target="_blank" href="https://www.instagram.com">
								<li className="fab fa-instagram"></li>
							</a>
						</div>
						<div>
							<a target="_blank" href="https://www.linkedin.com">
								<li className="fab fa-linkedin"></li>
							</a>
						</div>
					</ul>
				</div>
			</>
		);
	}
}

export default Sidebar;
