import React from "react";
import { Redirect, Route } from "react-router-dom";

export const PrivateRoute = ({
  component: Comp,
  isLoggedIn,
  isLoading,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoading ? (
          "Loading..."
        ) : isLoggedIn ? (
          <Comp {...props} {...rest} />
        ) : (
          <Redirect to="/404" />
        )
      }
    />
  );
};

export const AdminRoute = ({
  component: Comp,
  isAdmin,
  isLoading,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoading ? (
          "Loading..."
        ) : isAdmin ? (
          <Comp {...props} {...rest} />
        ) : (
          <Redirect to="/404" />
        )
      }
    />
  );
};
