import React, { Component } from "react";
import "./css/about.css";
import { Spring } from "react-spring/renderprops.js";

import wwwImg from "../images/www.png";
import databaseImg from "../images/database.png";
import bankIdImg from "../images/bankid.png";
import swishImg from "../images/swish_logo1.png";
import chainImg from "../images/chain.png";
import solidityImg from "../images/solidity.png";
import workerChris from "../images/profile-christoffer.png"
import workerChris2 from "../images/profile-christian.png"
import workerDani from "../images/profile-daniel.png"
import HomeBG from "../images/Home-BG.mp4";

import ContactForm from "../components/contact-form.js";

const flows = [
	{
		alt: "Website",
		src: wwwImg,
		text: "Hemsidor & Webbapplikationer",
		class: "logo"
	},
	{
		alt: "Database",
		src: databaseImg,
		text: "Databashantering",
		class: "logo"
	},
	{
		alt: "BankID",
		src: bankIdImg,
		text: "BankID",
		class: "logo"
	},
	{
		alt: "Swish",
		src: swishImg,
		text: "Swish",
		class: "logo"
	},
	{
		alt: "Blockchain",
		src: chainImg,
		text: "Blockchain utveckling",
		class: "logo"
	},
	{
		alt: "Solidity",
		src: solidityImg,
		text: "Solidity (Smart Contracts)",
		class: "logo height"
	}
];

class About extends Component {
	constructor() {
		super();
		this.state = {
			ContactFormVisible: false,
		};

		this.openContactForm = this.openContactForm.bind(this);
		this.closeContactForm = this.closeContactForm.bind(this);
	}

	openContactForm() {
		this.setState({
			ContactFormVisible: true,
		});
	}

	closeContactForm() {
		this.setState({
			ContactFormVisible: false,
		});
	}

	componentDidMount() {
		const HomeBG = document.querySelector(".Home-BG");
		const HomeBG2 = document.querySelector(".Home-BG-2");

		const setBackgrounds = (HomeBG, HomeBG2) => {
			HomeBG.play();
			HomeBG.onended = function () {
				HomeBG.classList.add("Video-Done");
				HomeBG2.classList.remove("Video-Done");
				HomeBG2.play();
				HomeBG2.onended = function () {
					HomeBG.classList.remove("Video-Done");
					HomeBG2.classList.add("Video-Done");
					setBackgrounds(HomeBG, HomeBG2);
				};
			};
		};

		HomeBG.addEventListener("loadeddata", function () {
			if (HomeBG.readyState >= 2) {
				setBackgrounds(HomeBG, HomeBG2);
			}
		});
	}

	render() {
		return (
			<>
				<Spring
					config={{ duration: 100 }}
					from={{ opacity: "0" }}
					to={{ opacity: "0.08" }}
				>
				{(HomeProps) => (
				<div className="Path-Container">
					<div className="Path-About">
						<div className="Home-BG-Wrapper">
							<video
								style={HomeProps}
								className="Home-BG"
								autoPlay={true}
								muted={true}
								playsInline={true}
							>
								<source src={HomeBG} type="video/mp4" />
								Your browser does not support the video
								tag.
							</video>
							<video
								className="Home-BG-2 Video-Done"
								autoPlay={true}
								muted={true}
								playsInline={true}
							>
								<source src={HomeBG} type="video/mp4" />
								Your browser does not support the video
								tag.
							</video>
						</div>
						<div className="P-W">
							<div className="Info-Start Padded">
								<h1>Företaget</h1>
								<div className="About-Information">
									<p>Kodarkivet erbjuder erfarna IT-konsulter som strävar efter att utveckla och leverera skräddarsydda produkter som möter specifika behov, oavsett kundens storlek eller bransch.</p>
									<p>Vår breda kompetens gör det möjligt för oss att anpassa varje uppdrag fullt ut. Vi samarbetar aktivt med våra kunder för att generera och implementera innovativa idéer och skapa en välplanerad projektstrategi.</p>
									<p>Om ni önskar en detaljerad offert för ert uppdrag, vänligen förse oss med en utförlig beskrivning, och vi återkommer inom 24 timmar.</p>
									<button onClick={this.openContactForm}>Kontakta oss</button>
								</div>
							</div>
							<div className="About-Flow Padded">
								<div>
									{flows.map((flow, i) => {
										if (i < 3)
											return <>
												<span key={i.toString()}>
													<div className={flow.class}>
														<img alt={flow.alt} src={flow.src}></img>
													</div>
													<p>{flow.text}</p>
												</span>
											</>
										else
											return <></>
									})}
								</div>
								<div>
									{flows.map((flow, i) => {
										if (i > 2)
											return <>
												<span key={i.toString()}>
													<div className={flow.class}>
														<img alt={flow.alt} src={flow.src}></img>
													</div>
													<p>{flow.text}</p>
												</span>
											</>
										else
											return <></>
									})}
								</div>
							</div>

							<div className="About-Cards Padded">
								<div className="About-Card">
									<div className="About-Card-Image">
										<img src={workerChris}></img>
									</div>
									<div className="About-Card-Contact">
										<p>Christoffer Bergström</p>
										<p className="title">VD / IT-Konsult</p>
										<a href="mailto:christian@kodarkivet.se">
											<p className="email">christoffer@kodarkivet.se</p>
										</a>
									</div>
								</div>
								<div className="About-Card">
									<div className="About-Card-Image Fixed">
										<img src={workerChris2}></img>
									</div>
									<div className="About-Card-Contact">
										<p>Christian Borg</p>
										<p className="title">IT-Konsult</p>
										<a href="mailto:christian@kodarkivet.se">
											<p className="email">christian@kodarkivet.se</p>
										</a>
									</div>
								</div>
								<div className="About-Card">
									<div className="About-Card-Image">
										<img src={workerDani}></img>
									</div>
									<div className="About-Card-Contact">
										<p>Daniél Gonzales</p>
										<p className="title">UX / Graphic Designer</p>
										<a href="mailto:christian@kodarkivet.se">
											<p className="email">daniel@kodarkivet.se</p>
										</a>
									</div>
								</div>
							</div>
							<div className="About-Exps Padded">
								<p>Python</p>
								<p>Rust</p>
								<p>PHP</p>
								<p>C#</p>
								<p>Solidity</p>
								<p>Web3</p>
								<p>React</p>
								<p>HTML</p>
								<p>CSS/SASS/LESS</p>
								<p>Javascript</p>
								<p>Wordpress</p>
								<p>MySQL</p>
								<p>PostgreSQL</p>
								<p>MS SQL</p>
							</div>
						</div>
					</div>
				</div>
				)}
				</Spring>
				{this.state.ContactFormVisible ? (
					<ContactForm
						handleMessage={this.props.handleMessage}
						closeContactForm={this.closeContactForm}
					/>
				) : null}
			</>
		);
	}
}

export default About;
