import React from "react";
import { SendMail } from "../helpers/send-mail.js";
import Loading from "./loading.js";

import "./css/iphone-contact-form.css";

class ContactForm extends React.Component {
	constructor() {
		super();

		this.state = {
			loadingText: "",
			form: {
				name: null,
				phone: null,
				email: null,
				message: null,
			},
		};

		this.handleUnFocus = this.handleUnFocus.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
	}

	async handleSubmit(e) {
		e.preventDefault();

		this.setState({
			loadingText: "Skickar e-post!"
		});

		const form = document.querySelector(".iPhone-Contact-Form");
		const loading = document.querySelector(".iPhone-Loading");

		form.classList.add("Hidden");
		loading.classList.remove("Hidden");

		SendMail(this.state.form)
		.then((response) => {
			if (response.data.sent === true) {
				this.setState({
					loadingText: "Tack för ditt meddelande. En konsult kontaktar er inom 24 timmar."
				});

				this.clearForm();
			} else {
				this.setState({
					loadingText: "Något gick snett när vi försökte skicka meddelandet, försök igen!"
				});
			}

			setTimeout(() => {
				loading.classList.add("Hidden");
				form.classList.remove("Hidden");
			}, 4000);
		})
		.catch((err) => {
			this.setState({
				loadingText: "Något gick snett när vi försökte skicka meddelandet, försök igen!"
			});

			setTimeout(() => {
				loading.classList.add("Hidden");
				form.classList.remove("Hidden");
			}, 1000);
		});
	}

	handleInputChange(e) {
		const name = e.target.name;
		const value = e.target.value;
		this.setState({
			form: {...this.state.form, [name]: value}
		});
	}

	handleUnFocus(e) {
		const Input = e.target;
		Input.value
			? Input.classList.add("Has-Value")
			: Input.classList.remove("Has-Value");
	}

	clearForm() {
		document.querySelector(".iPhone-Contact-Form").reset();
		document.querySelectorAll(".Has-Value").forEach((e) => {
			e.classList.remove("Has-Value");
		});
	}

	render() {
		return (
			<>
				<form
					className="iPhone-Contact-Form"
					onSubmit={this.handleSubmit}
				>
					<h3>Kontakta oss</h3>
					<div className="Wrap-Input">
						<input
							type="text"
							name="name"
							onChange={this.handleInputChange}
							onBlur={this.handleUnFocus}
							className="Input"
							required
						></input>
						<span className="Focus-Input">Namn</span>
					</div>
					<div className="Wrap-Input">
						<input
							type="text"
							name="phone"
							onChange={this.handleInputChange}
							onBlur={this.handleUnFocus}
							className="Input"
							required
						></input>
						<span className="Focus-Input">Telefonnummer</span>
					</div>
					<div className="Wrap-Input">
						<input
							type="email"
							name="email"
							onChange={this.handleInputChange}
							onBlur={this.handleUnFocus}
							className="Input"
							required
						></input>
						<span className="Focus-Input">E-post</span>
					</div>
					<div className="Wrap-Input Message">
						<textarea
							name="message"
							onChange={this.handleInputChange}
							onBlur={this.handleUnFocus}
							rows="4"
							className="Input"
							required
						></textarea>
						<span className="Focus-Input">Meddelande</span>
					</div>
					<button type="submit">Skicka</button>
				</form>
				<div className="iPhone-Loading Hidden">
					<Loading loadingText={this.state.loadingText}/>
				</div>
			</>
		);
	}
}

export default ContactForm;
