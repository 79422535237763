import React from "react";
import { UnsubscribeMail } from "../helpers/send-mail.js";

class Unsubscribe extends React.Component {
  constructor(props) {
		super(props);

    const query = new URLSearchParams(this.props.location.search);

		this.state = {
			email: query.get('email')
		};
	}

  componentDidMount() {
    UnsubscribeMail(this.state.email);
  }

  render() {
    return (
      <>
        <div className="Path-Container">
          <div style={{textAlign: "center"}} className="P-W">
            <h1>Nyhetsbrev avslutad</h1>
            <p>Kodarkivet skickar inte längre ut nyhetsbrev till {this.state.email}.</p>
          </div>
        </div>
      </>
    );
  }
}

export default Unsubscribe;
