import React from "react";

class FourZeroFour extends React.Component {
  render() {
    return (
      <>
        <div className="Path-Container">
          <div className="P-W">
            <h1>Ingen åtkomst</h1>
            <h1>404</h1>
            <p>Sidan du försöker visa finns inte eller så saknar du åtkomst.</p>
            <p>
              <a href={"/"} style={{ color: "#0056b3" }}>
                Klicka här
              </a>{" "}
              för att komma till startsidan.
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default FourZeroFour;
