import React from "react";
import Login from "../components/login.js";

class LoginPath extends React.Component {
	render() {
		return (
			<>
				<div className="Path-Container">
					<Login handleLogin={this.props.handleLogin} />
				</div>
			</>
		);
	}
}

export default LoginPath;
