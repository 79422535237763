import React from "react";
import { Spring } from "react-spring/renderprops.js";
import ContactForm from "../components/contact-form.js";
import Iphone from "../components/iphone.js";

import "./css/home.css";

import HomeBG from "../images/Home-BG.mp4";

class Home extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			ContactFormVisible: false,
		};

		this.openContactForm = this.openContactForm.bind(this);
		this.closeContactForm = this.closeContactForm.bind(this);
	}

	openContactForm() {
		const iPhone = document.querySelector(".iPhone-Screen");

		if (this.props.burgerState === true) {
			this.setState({
				ContactFormVisible: true,
			});
		} else {
			iPhone.scrollTop = iPhone.scrollHeight;
		}
	}

	closeContactForm() {
		this.setState({
			ContactFormVisible: false,
		});
	}

	componentDidMount() {
		const HomeBG = document.querySelector(".Home-BG");
		const HomeBG2 = document.querySelector(".Home-BG-2");

		const setBackgrounds = (HomeBG, HomeBG2) => {
			HomeBG.play();
			HomeBG.onended = function () {
				HomeBG.classList.add("Video-Done");
				HomeBG2.classList.remove("Video-Done");
				HomeBG2.play();
				HomeBG2.onended = function () {
					HomeBG.classList.remove("Video-Done");
					HomeBG2.classList.add("Video-Done");
					setBackgrounds(HomeBG, HomeBG2);
				};
			};
		};

		HomeBG.addEventListener("loadeddata", function () {
			if (HomeBG.readyState >= 2) {
				setBackgrounds(HomeBG, HomeBG2);
			}
		});
	}

	render() {
		return (
			<>
				<Spring
					config={{ duration: 100 }}
					from={{ opacity: "0" }}
					to={{ opacity: "0.15" }}
				>
					{(HomeProps) => (
						<div className="Path-Container">
							<div className="Path-Home">
								<div className="Home-BG-Wrapper">
									<video
										style={HomeProps}
										className="Home-BG"
										autoPlay={true}
										muted={true}
										playsInline={true}
									>
										<source src={HomeBG} type="video/mp4" />
										Your browser does not support the video
										tag.
									</video>
									<video
										className="Home-BG-2 Video-Done"
										autoPlay={true}
										muted={true}
										playsInline={true}
									>
										<source src={HomeBG} type="video/mp4" />
										Your browser does not support the video
										tag.
									</video>
								</div>
								<div className="P-W">
									<div className="Padded">
										<h1>
											Allt inom{" "}
											<span
												style={{
													color: "#2d5ca8",
													fontWeight: "300",
												}}
											>
												webb-,<br></br> systemutveckling
											</span>{" "}
											&{" "}
											<span
												style={{
													color: "#4286f4",
													fontWeight: "300",
												}}
											>
												digital marknadsföring
											</span>{" "}
										</h1>
										<h3>
											Genom grundlig behovsanalys erbjuder vi skräddarsydda, högkvalitativa lösningar för kunder i alla storlekar och branscher. Läs mer om Kodarkivet {" "}
											<a
												style={{
													color: "#2371d3",
												}}
												href="/about"
											>
												här
											</a>
											.
										</h3>
										<button onClick={this.openContactForm}>Kontakta oss</button>
									</div>
								</div>
								<div className="Home-Iphone">
									<Iphone
										handleMessage={this.props.handleMessage}
										closeContactForm={this.closeContactForm}
									/>
								</div>
							</div>
						</div>
					)}
				</Spring>
				{this.props.burgerState ? (
					this.state.ContactFormVisible ? (
						<ContactForm
							handleMessage={this.props.handleMessage}
							closeContactForm={this.closeContactForm}
						/>
					) : null
				) : null}
			</>
		);
	}
}

export default Home;
