import axios from "axios";
import {config} from "../config.js";

export function VerifyUser(token) {
	return new Promise((resolve) => {
		axios
			.post(config.protocol + "://" + config.domain.api + "/verify", {
				token: token,
			})
			.then((response) => {
				resolve(response.data.user);
			})
			.catch((error) => {
				console.error(error);
			});
	});
}

export function VerifyAdmin(token) {
	return new Promise((resolve) => {
		axios
			.post(config.protocol + "://" + config.domain.api + "/verifyadmin", {
				token: token,
			})
			.then((response) => {
				resolve(response.data.user);
			})
			.catch((error) => {
				console.error(error);
			});
	});
}
