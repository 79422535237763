import React, { Component } from "react";
import { Spring } from "react-spring/renderprops.js";
import bankid_logo from "../images/bankid.png";
import approva_logo from "../images/approva.png";
import swish_logo from "../images/swish_logo2.png";
import johnbeijer_logo from "../images/johnbeijer.png";
import bytbilar_logo from "../images/bytbilar.png";
import revera_logo from "../images/revera.png";
import prima_logo from "../images/primabostader.png";
import HomeBG from "../images/Home-BG.mp4";

import "./css/portfolio.css";

class Portfolio extends Component {
	rotateProject(e) {
		const Project = e.target;
		Project.classList.toggle("rotated");
	}

	render() {
		return (
			<>
				<Spring
					config={{ duration: 100 }}
					from={{ opacity: "0" }}
					to={{ opacity: "1" }}
				>
					{(PortfolioProps) => (
						<div className="Path-Container">
							<div className="Path-Portfolio">
								<Spring
									config={{ duration: 100 }}
									from={{ opacity: "0" }}
									to={{ opacity: "0.08" }}
								>
								{(HomeProps) => (
								<div className="Home-BG-Wrapper">
									<video
										style={HomeProps}
										className="Home-BG"
										autoPlay={true}
										muted={true}
										playsInline={true}
									>
										<source src={HomeBG} type="video/mp4" />
										Your browser does not support the video
										tag.
									</video>
									<video
										className="Home-BG-2 Video-Done"
										autoPlay={true}
										muted={true}
										playsInline={true}
									>
										<source src={HomeBG} type="video/mp4" />
										Your browser does not support the video
										tag.
									</video>
								</div>
								)}
								</Spring>

								<div className="P-W Padded">
									<h1>Portfölj</h1>
									<div className="Portfolio-Projects">
										<div
											className="Portfolio-Project"
											style={PortfolioProps}
											onClick={this.rotateProject}
										>
											<div className="Portfolio-Project-Title">
												<h3>BankID Integrering</h3>
												<p>
													Kodarkivet har integrerat BankID hos
													några utav våra kunder.
													Deras användare kan nu
													verifiera sig digitalt.
												</p>
											</div>
											<div className="Portfolio-Project-Image">
												<img
													alt="ReactLogo"
													src={bankid_logo}
												/>
												<span>MER INFO</span>
											</div>
										</div>

										<div
											className="Portfolio-Project"
											style={PortfolioProps}
											onClick={this.rotateProject}
										>
											<div className="Portfolio-Project-Title">
												<h3>Swish Betalningar</h3>
												<p>
													Kodarkivet har integrerat Swish
													betalningar hos några utav
													våra kunder. De kan nu ta
													betalt, återbetala och se
													betalningshistorik i sina
													verktyg.
												</p>
											</div>
											<div className="Portfolio-Project-Image">
												<img
													alt="ReactLogo"
													src={swish_logo}
												/>
												<span>MER INFO</span>
											</div>
										</div>

										<div
											className="Portfolio-Project"
											style={PortfolioProps}
											onClick={this.rotateProject}
										>
											<div className="Portfolio-Project-Title">
												<h3>approva.se</h3>
												<p>
													Hemsida, CRM system som
													hanterar låneansökningar,
													utbetalningar, Fakturasystem
													& Ledgersystem. BankID
													verifiering och Swish
													betalningar.
												</p>
											</div>
											<div className="Portfolio-Project-Image">
												<img
													alt="ReactLogo"
													src={approva_logo}
												/>
												<span>MER INFO</span>
											</div>
										</div>

										<div
											className="Portfolio-Project"
											style={PortfolioProps}
											onClick={this.rotateProject}
										>
											<div className="Portfolio-Project-Title">
												<h3>bytbilar.se</h3>
												<p>
													Hemsida, med administrativt system för
													att hantera bilannonser.
													Swish betalningar integrerat
													för annonsbetalning.
												</p>
											</div>
											<div className="Portfolio-Project-Image">
												<img
													alt="ReactLogo"
													src={bytbilar_logo}
												/>
												<span>MER INFO</span>
											</div>
										</div>

										<div
											className="Portfolio-Project"
											style={PortfolioProps}
											onClick={this.rotateProject}
										>
											<div className="Portfolio-Project-Title">
												<h3>primabostader.se</h3>
												<p>
													Hemsida i wordpress med ett
													tillhörande administrativt system för att
													hantera lägenheter och
													fastigheter.
												</p>
											</div>
											<div className="Portfolio-Project-Image">
												<img
													alt="ReactLogo"
													src={prima_logo}
												/>
												<span>MER INFO</span>
											</div>
										</div>

										<div
											className="Portfolio-Project"
											style={PortfolioProps}
											onClick={this.rotateProject}
										>
											<div className="Portfolio-Project-Title">
												<h3>reveraredovisning.se</h3>
												<p>Hemsida och design av logotyp.</p>
											</div>
											<div className="Portfolio-Project-Image">
												<img
													alt="ReactLogo"
													src={revera_logo}
												/>
												<span>MER INFO</span>
											</div>
										</div>

										<div
											className="Portfolio-Project"
											style={PortfolioProps}
											onClick={this.rotateProject}
										>
											<div className="Portfolio-Project-Title">
												<h3>johnbeijer.com</h3>
												<p>
													Webbshop med Swish
													betalningar integrerat i
													wordpress.
												</p>
											</div>
											<div className="Portfolio-Project-Image">
												<img
													alt="ReactLogo"
													src={johnbeijer_logo}
												/>
												<span>MER INFO</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</Spring>
			</>
		);
	}
}

export default Portfolio;
