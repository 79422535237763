import axios from "axios";
import {config} from "../config.js";

export function SendMail(data) {
	return new Promise((resolve) => {
		axios
			.post(config.protocol + "://" + config.domain.api + "/mail/contact-form", {
				data: data,
			})
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				console.error(error);
			});
	});
};

export function UnsubscribeMail(email) {
	return new Promise((resolve) => {
		axios
			.get(config.protocol + "://" + config.domain.api + "/mail/unsubscribe?email=" + decodeURIComponent(email))
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				console.error(error);
			});
	});
};
