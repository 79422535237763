import React from "react";
import "./css/contact-form.css";
import { Spring } from "react-spring/renderprops.js";
import { SendMail } from "../helpers/send-mail.js";
import Loading from "./loading.js";

class ContactForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingText: "",
			form: {
				name: null,
				phone: null,
				email: null,
				message: null,
			},
		};

		this.handleUnFocus = this.handleUnFocus.bind(this);
		this.checkForEscape = this.checkForEscape.bind(this);
		this.checkForOutsideClick = this.checkForOutsideClick.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
	}

	async handleSubmit(e) {
		e.preventDefault();

		this.setState({
			loadingText: "Skickar e-post!"
		});

		const form = document.querySelector(".Offert-Form form");
		const loading = document.querySelector(".Offert-Loading");

		form.classList.add("Hidden");
		loading.classList.remove("Hidden");

		SendMail(this.state.form)
		.then((response) => {
			if (response.data.sent === true) {
				this.setState({
					loadingText: "Tack för ditt meddelande. En konsult kontaktar er inom 24 timmar."
				});

				this.clearForm();
			} else {
				this.setState({
					loadingText: "Något gick snett när vi försökte skicka meddelandet, försök igen!"
				});
			}

			setTimeout(() => {
				loading.classList.add("Hidden");
				form.classList.remove("Hidden");
			}, 4000);
		})
		.catch((err) => {
			this.setState({
				loadingText: "Något gick snett när vi försökte skicka meddelandet, försök igen!"
			});

			setTimeout(() => {
				loading.classList.add("Hidden");
				form.classList.remove("Hidden");
			}, 1000);
		});
	}

	handleInputChange(e) {
		const name = e.target.name;
		const value = e.target.value;
		this.setState({
			form: {...this.state.form, [name]: value}
		});
	}

	handleUnFocus(e) {
		const Input = e.target;
		Input.value
			? Input.classList.add("Has-Value")
			: Input.classList.remove("Has-Value");
	}

	checkForEscape = (event) => {
		const key = event.key;
		if (key === "Escape") {
			this.props.closeContactForm();
		}
	};

	checkForOutsideClick(event) {
		const OffertWrapper = document.querySelector(".Offert-Wrapper");
		if (event.srcElement === OffertWrapper) {
			this.props.closeContactForm();
		}
	}

	clearForm() {
		document.querySelector(".Offert-Form form").reset();
		document.querySelectorAll(".Has-Value").forEach((e) => {
			e.classList.remove("Has-Value");
		});
	}

	componentDidMount() {
		const OfferWrapper = document.querySelector(".Offert-Wrapper");

		document.addEventListener("keydown", this.checkForEscape);
		OfferWrapper.addEventListener("click", this.checkForOutsideClick);
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.checkForEscape);
	}

	render() {
		return (
			<>
				<Spring
					config={{ duration: 200 }}
					from={{ transform: "translateX(-50%) translateY(-200%)" }}
					to={{ transform: "translateX(-50%) translateY(-50%)" }}
				>
					{(windowProps) => (
						<div className="Offert-Wrapper">
							<div style={windowProps} className="Offert-Form">
								<i onClick={this.props.closeContactForm} className="Exit-ContactForm fas fa-times"></i>
								<h1>Kontakta oss</h1>
								<p>Skriv ett meddelande med en förklaring om vad du behöver. Du uppskattas få svar inom 24 timmar.</p>
								<form onSubmit={this.handleSubmit}>
									<div className="Wrap-Input">
										<input
											type="text"
											name="name"
											onChange={this.handleInputChange}
											onBlur={this.handleUnFocus}
											className="Input"
											required
										></input>
										<span className="Focus-Input">Namn</span>
									</div>
									<div className="Wrap-Input">
										<input
											type="text"
											name="phone"
											onChange={this.handleInputChange}
											onBlur={this.handleUnFocus}
											className="Input"
											required
										></input>
										<span className="Focus-Input">Telefonnummer</span>
									</div>
									<div className="Wrap-Input">
										<input
											type="email"
											name="email"
											onChange={this.handleInputChange}
											onBlur={this.handleUnFocus}
											className="Input"
											required
										></input>
										<span className="Focus-Input">E-post</span>
									</div>
									<div className="Wrap-Input Message">
										<textarea
											name="message"
											onChange={this.handleInputChange}
											onBlur={this.handleUnFocus}
											rows="4"
											className="Input"
											required
										></textarea>
										<span className="Focus-Input">Meddelande</span>
									</div>
									<button type="submit">Skicka</button>
								</form>
								<div className="Offert-Loading Hidden">
									<Loading loadingText={this.state.loadingText} />
								</div>
							</div>
						</div>
					)}
				</Spring>
			</>
		);
	}
}

export default ContactForm;
