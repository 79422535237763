import React from "react";
import "./css/loading.css";
import LoadingIcon from "../images/loading.gif";


class Loading extends React.Component {
	componentDidMount() {
		
	}

	render() {
		return (
			<>
				<div className="LoadingBox">
					<img alt="Loading-Icon" src={LoadingIcon}></img>
					<p className="LoadingText">{this.props.loadingText}</p>
				</div>
			</>
		);
	}
}

export default Loading;
