import React from "react";
import "./css/message-box.css";

class MessageBox extends React.Component {
	componentDidMount() {
		const MessageBox = document.querySelector(".MessageBox");
		setTimeout(function () {
			MessageBox.classList.add("show");
		}, 50);

		setTimeout(
			function () {
				this.props.handleMessage("");
			}.bind(this),
			4000
		);
	}

	render() {
		return (
			<>
				<div className="MessageBox">
					<p className="MessageText">{this.props.MessageText}</p>
				</div>
			</>
		);
	}
}

export default MessageBox;
